import React from 'react'
import { graphql } from 'gatsby'
import { format, parseISO } from 'date-fns'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText } from '@prismicio/react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'

export const query = graphql`
  query Blogpost($id: String) {
    prismicBlogpost(id: { eq: $id }) {
      ...PrismicBlogpostFragment
    }
  }
`

const BlogpostTemplate = (props) => {
  const blogpostData = props.data.prismicBlogpost.data

  if (props.data.prismicBlogpost.first_publication_date) {
    var parseDate = parseISO(props.data.prismicBlogpost.first_publication_date)
    var formattedDate = format(new Date(parseDate), 'EEEE, do MMMM yyyy')
  }

  const wordsPerMinute = 200
  let wordCount = blogpostData.blogpost_text.text.split(' ').length
  let timeToRead = Math.ceil(wordCount / wordsPerMinute)

  let images = blogpostData.blogpost_image?.gatsbyImageData
  if (blogpostData.blogpost_image.gatsbyImageData) {
    images = withArtDirection(getImage(blogpostData.blogpost_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(blogpostData.blogpost_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  return (
    <Layout>
      <div className='grid h-full grid-cols-1 grid-rows-1 overflow-hidden bg-white md:max-h-screen-1/3'>
        {images && (
          <div className='art-directed relative col-span-full row-span-full h-full bg-gray-300'>
            <GatsbyImage className='absolute inset-0 h-full w-full object-cover mix-blend-multiply' image={images} alt={blogpostData.blogpost_image.alt || ''} />
          </div>
        )}
        <div className='hero relative col-span-full row-span-full flex w-full flex-col justify-center py-28 px-4 text-center md:mx-auto md:max-w-screen-xl'>
          <div className='mx-auto flex flex-col items-stretch justify-center text-white md:max-w-screen-md'>
            <PrismicRichText field={blogpostData.blogpost_title.richText} />
            <div className='text-center md:mt-5'>
              {blogpostData.author[0].image.gatsbyImageData && (
                <GatsbyImage
                  className='my-2 mx-auto h-16 w-16 rounded-full border border-black bg-white p-2'
                  image={blogpostData.author[0].image.gatsbyImageData}
                  alt={blogpostData.author[0].image.alt || ''}
                />
              )}
              <div className='ml-3 text-center'>
                <p className='font-semibold text-white'>
                  {blogpostData.author[0].name}
                  {blogpostData.author[0].position && (
                    <>
                      <span aria-hidden='true'> &middot; </span>
                      <span> {blogpostData.author[0].position}</span>
                    </>
                  )}
                </p>
                {parseDate && (
                  <div className='text-white'>
                    <time dateTime={parseDate}>{formattedDate}</time>
                    <span aria-hidden='true'> &middot; </span>
                    <span>{timeToRead} min read</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mx-auto w-full max-w-screen-xl p-4 pb-10 md:p-8'>
        <div className='prose prose-lg mx-auto'>
          <PrismicRichText field={blogpostData.blogpost_text.richText} />
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(BlogpostTemplate)

export const Head = ({ data, location }) => {
  const pageData = data.prismicBlogpost.data
  return (
    <Seo
      authorData={pageData.author[0]}
      datePublished={data.prismicBlogpost.first_publication_date}
      dateModified={data.prismicBlogpost.last_publication_date}
      description={pageData.meta_description}
      image={pageData.blogpost_image.url}
      isBlogPost={true}
      pathname={location.pathname}
      title={pageData.meta_title ? pageData.meta_title : pageData.blogpost_title.text}
    />
  )
}
